var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"modalTable"}},[_c('b-modal',{attrs:{"id":_vm.id,"cancel-variant":"outline-primary","ok-title":"Lưu","cancel-title":"Hủy bỏ","centered":"","no-close-on-backdrop":"","title":_vm.title},on:{"ok":function($event){return _vm.accept($event)},"hidden":_vm.resetData,"show":function($event){return _vm.getListTraining(_vm.urlQuery)}}},[_c('validation-observer',{ref:"rule"},[_c('button-all-header',{attrs:{"hideAdd":false,"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"arrayExcel":[],"hideDelete":false},on:{"clickDelete":function () {},"clickDowloadSample":function () {},"clickExportExcel":function () {},"importFile":function () {},"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }},on:{"on-selected-rows-change":_vm.selectRowTableModal},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(props.row.code))])]):(props.column.field === 'name')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.name))])]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }