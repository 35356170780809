<template lang="">
  <div
    id="modalTable"
  >
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
      @show="
        getListTraining(urlQuery)"
    >
      <validation-observer ref="rule">
        <button-all-header
          :hideAdd="false"
          :hideImportFile="false"
          :hideExportFile="false"
          :hideDowload="false"
          :arrayExcel="[]"
          :hideDelete="false"
          @clickDelete="() => {}"
          @clickDowloadSample="() => {}"
          @clickExportExcel="() => {}"
          @importFile="() => {}"
          @search="search($event)"
        />
        <vue-good-table
          ref="user-table"
          style-class="vgt-table"
          :columns="columns"
          :rows="dataList || []"
          :select-options="{
            enabled: true,
            vertialAlignTop: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          @on-selected-rows-change="selectRowTableModal"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column:Mã người dùng -->
            <span
              v-if="props.column.field === 'code'"
              class="text-nowrap"
            >
              <span>{{ props.row.code }}</span>
            </span>

            <!-- Column:Chức năng -->
            <span v-else-if="props.column.field === 'name'">
              <span>{{ props.row.name }}</span>
            </span>
          </template>
        </vue-good-table>
        <!-- pagination -->

        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="pageChange"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/Constants'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueGoodTable,
    ButtonAllHeader,
    MyPagination,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      totalRecord: 10,
      listModel: [],

      dataList: [],
      required,
      columns: [
        {
          label: 'MÃ NGƯỜI LAO ĐỘNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
          sortable: false,
        },
      ],
      urlQuery: {
        key: '',
        targetTrainingId: '',
        pageSize: 10,
        pageNumber: 1,
      },
    }
  },
  methods: {
    // Lấy giá trị
    selectRowTableModal(val) {
      this.listModel = []
      val.selectedRows.forEach(element => {
        const { id } = element
        this.listModel.push(id)
      })
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      const message = 'Chọn ít nhất một người lao động'
      if (this.listModel.length === 0) {
        this.$root.$bvToast.toast(message, {
          title: 'Thông báo', variant: 'warning', toaster: this.$toastPosition, solid: true,
        })
      } else {
        this.$refs.rule.validate().then(success => {
          if (success) {
            this.$emit('accept', this.listModel)
            this.getListTraining(this.urlQuery)
          }
        })
      }
    },
    resetData() {
      this.getListTraining(this.urlQuery)
    },
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getListTraining(this.urlQuery)
    },
    async getListTraining(urlQuery) {
      this.$showLoading()
      this.urlQuery.targetTrainingId = this.$route.params.id
      const res = await axiosApiInstance.get(ConstantsApi.TARGET_NO_REGISTER, { params: urlQuery })
      this.dataList = res?.data?.data.pageLists
      this.totalRecord = res?.data?.data?.totalRecord
      this.$hideLoading()
    },
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.getListTraining(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.getListTraining(this.urlQuery)
      }
    },
  },
}
</script>
<style lang="scss">
#modalTable{
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
  .modal-dialog{
    max-width: 800px !important;
  }
}
</style>
