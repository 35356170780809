<template>
  <div
    id="group-training"
    class="page-container-table"
  >
    <button-all-header
      :contentBtnAdd="'Thêm người lao động'"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @importFile="() => {}"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã người dùng -->
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <span>{{ props.row.code }}</span>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <feather-icon
            v-b-tooltip.hover.top="'Xóa'"
            icon="Trash2Icon"
            size="18"
            class="text-body ml-2"
            @click="deleteItem(props.row.id)"
          />
        </span>
      </template>
    </vue-good-table>
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- pagination -->

    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />
    <!-- Hiển thị modal create -->
    <modal-create
      :id="modalIdCreate"
      title="Thêm người lao động"
      @accept="handleModal"
    />
  </div>
</template>

<script>

import {
  VBTooltip,
} from 'bootstrap-vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ModalCreate from '@/views/management-workers/group-training/pages/components/ModalCreate.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/Constants'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    ModalCreate,
    ConfirmModal,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      totalRecord: 10,
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalIdCreate: 'modalTable',
      dataList: [],
      columns: [
        {
          label: 'MÃ NGƯỜI LAO ĐỘNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      urlQuery: {
        targetTrainingId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    async fetchData(urlQuery) {
      this.$showLoading()
      this.urlQuery.targetTrainingId = this.$route.params.id
      const res = await axiosApiInstance.get(ConstantsApi.TARGET_TRAINING, { params: urlQuery })
      this.dataList = res?.data?.data?.pageLists
      this.totalRecord = res?.data?.data?.totalRecord
      this.$hideLoading()
    },
    async handleModal(val) {
      const payload = {
        targetTrainingId: this.$route.params.id,
        workerIds: val,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_TARGET_TRAINING, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalIdCreate)
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalIdCreate)
      })
    },
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_TARGET_TRAINING, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    showModalCreate() {
      this.$bvModal.show(this.modalIdCreate)
    },
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
#group {
.search-input {
    width: 400px;
  }
 .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
